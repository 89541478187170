import { useState,useRef, useEffect } from 'react'
import BurgerMenuSvg  from '../assets/burger-menu.svg'
import CloseSvg from '../assets/close.svg'


const DropDown = ({item}) => {
  return (<li><button>
            {item['title']}
            <svg aria-hidden="true" height="16" viewBox="0 0 16 16" version="1.1" width="16">
                <path
                    d="M12.78 5.22a.749.749 0 0 1 0 1.06l-4.25 4.25a.749.749 0 0 1-1.06 0L3.22 6.28a.749.749 0 1 1 1.06-1.06L8 8.939l3.72-3.719a.749.749 0 0 1 1.06 0Z">
                </path>
            </svg>
        </button>
        <div className="dropdown__wrapper">
            <div className="dropdown">
                <ul className="list-items-with-description">
                      {item.items.map((i, index) => {
                        return (<DropDownExternalLink key={index} item={i}/>)
                      })}
                </ul>
            </div>
        </div></li>)
}

const DropDownExternalLink = ({item}) => {
  return ( <li>
            <div className="item-title">
              <a href={item['link']} target="_blank" title={item['title']}>
                  {item['title']}
              </a>
              <p>{item['description']}</p>
            </div>
          </li>)
}

const ExternalLink = ({item}) => {
  return ( <li>
            <a href={item['link']} target="_blank" title={item['title']}>
                {item['title']}
            </a>
          </li>)
}

const Dialog = ({item}) => {
  return ( <li>
            <a onClick={() => item.excute()}>
                {item['title']}
            </a>
          </li>)
}


const Login = () => {
  return (        <div className="action-buttons hide">
          <a href="#log-in" title="Log in" className="secondary">
              Log In
          </a>
          <a href="#sign-up" title="Sign up" className="primary">
              Sign up
          </a>
      </div>)
}

const HeaderNavigation = (props) => {
  const [svg, setSvg] = useState(BurgerMenuSvg)
  const [ncn, setNcn] = useState('navigation')

  
  const [urlList, setUrlList] = useState([
    {
      type: 'externalLink',
      title: 'Docs',
      link: 'https://docs.feishu.cn'
    },
    {
      type: 'dropdown',
      title: 'Features',
      items: [
        {
          type: 'externalLink',
          title: 'Documents',
          description: 'Serverless storage for frontend',
          link: 'https://docs.feishu.cn'
        }
      ],
      link: 'https://docs.feishu.cn'
    },
  ])

  useEffect(() => {
    setUrlList(props.urlList)
  }, [props.urlList])


  const toggleMenu = () => {
    const isBurger = svg == BurgerMenuSvg;
    const iconName = isBurger ? CloseSvg : BurgerMenuSvg;
  
    console.log(isBurger)
    setSvg(iconName)
  
    if (!isBurger) {
      setNcn("navigation navigation--mobile--fadeout");
      setTimeout(() => {
        setNcn("navigation");
      }, 300);
    } else {
      setNcn("navigation navigation--mobile");
    }
  };

  
  return (    <header className="menu__wrapper">
        <button onClick={() => toggleMenu()} aria-label="Open menu" className="burger-menu menu-icon" type="button">

        <img className="menu-icon" src={svg} title='Burger Menu' alt='Burger Menu'
            onClick={() => {toggleMenu()}}/>      
        </button>
      
        <div className="menu__bar">
            <a href="#" title="Home" aria-label="home" className="logo">
                <svg aria-label="Bamboo desktop" height="22" role="img" viewBox="0 0 283 64">
                    <path
                        d="">
                    </path>
                </svg>
            </a>
            <nav>
                <ul className={ncn}>
                    {urlList.map((i, index) => {
                      if (i.type == 'externalLink') {
                        return (<ExternalLink item={i} key={index}/>)
                      }
                      if (i.type == 'dropdown') {
                        return (<DropDown item={i} key={index}/>)
                      }
                      if (i.type == 'dialog') {
                        i.excute = props.dialog[i['link']]
                        return (<Dialog item={i} key={index}/>)
                      }                      
                    })}
                </ul>
            </nav>
        </div>
        </header>)
}

export {HeaderNavigation}