import { useState,useRef, useEffect } from 'react'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
          Button,
          Card,
          Select,
          MenuItem,
          CardContent,
          Typography,
          Dialog,
          DialogTitle,
          DialogContent,
          DialogActions,
          Tabs,
          Tab,
          Typography,
          Box,
          TextField
       } from '@mui/material';
import axios from 'axios'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paperScrollPaper': {
    overflowY: 'unset',
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}




const SettingPanel = (props) => {
  const [urlList, setUrlList] = useState([])
  const [bgUrls, setBgUrls] = useState([])  
  const [value, setValue] = useState(0);
  const fileInput = useRef(null);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  
  
  useEffect(() => {
     if (localStorage.getItem('urlList')) {
        try{setUrlList(JSON.parse(localStorage.getItem('urlList')))} catch(e){console.log(e)}
        window.urlList = [...urlList]
     }
     if (localStorage.getItem('bgUrls')) {
        try{setBgUrls(JSON.parse(localStorage.getItem('bgUrls')))} catch(e){console.log(e)}
        window.bgUrls = [...bgUrls]
     }
  },[])


   console.log(urlList)
   const handleClose = () => {
      props.setShowBambooSettings(false)
   }


 
   const handleNavigationChange = (i, newItem) => {
      let u = urlList.filter((item, index) => index == i)[0]
      u = {...newItem}
      setUrlList([...urlList.slice(0,i),u, ...urlList.slice(i+1)])
      handleSave([...urlList.slice(0,i),u, ...urlList.slice(i+1)])
   }
  
   
   const handleSave = (urls = null) => {
      if (urls != null) {
        localStorage.setItem('urlList', JSON.stringify(urls))
        window.urlList = [...urlList]
      } else {
        localStorage.setItem('urlList', JSON.stringify(urlList))
        window.urlList = [...urlList]
        localStorage.setItem('bgUrls', JSON.stringify(bgUrls))
        window.bgUrls = [...bgUrls]     
        handleClose()
      }
      props.refreshSiteList()

   }

  
   const addNewUrls = () => {
    const u = {
        "link": "http://",
        "title": "",
        "type": "externalLink",
        "display": "bottom",
        "token": "",
        "items": [],
        "index": "",
        "style": {"bgColor": ['#FFFBC7','#EAEEF1','#DCC7E1'][Math.floor(Math.random() * 3)]}
    }
    setUrlList([...urlList, u])
    localStorage.setItem('urlList', JSON.stringify([...urlList, u]))    
   }

   const handleNavigationDelete = (i) => {
    setUrlList([...urlList.filter((item, index) => !(index == i))])
    localStorage.setItem('urlList', JSON.stringify([...urlList.filter((item, index) => !(index == i))]))
   }


  const DropdownItemEditor = ({item, index, handleDropdownDelete, handleDropDownChange}) => {
    const [title, setTitle] = useState(item['title'])
    const [link, setLink] = useState(item['link'])

    const handleDelete = () => {
      handleDropdownDelete(index)
    }

    const handleSave = (e, t) => {
      if (t=='title') {
        setTitle(e.target.value)
        handleDropDownChange(index, {...item, title: e.target.value})
      }
      if (t=='link') {
        setLink(e.target.value)
        handleDropDownChange(index, {...item, link: e.target.value})
      }      
    }    
    
    return (<div stylle={{}}>
                            <TextField label='Title' fullWidth value={title} onChange={(e) => handleSave(e, 'title')}/>
                           <TextField label='Link' fullWidth value={link} onChange={(e) => handleSave(e, 'link')}/>
                          <div  style={{display: 'flex', justifyContent: 'flex-end',width: '100%'}}>
                           <Button onClick={() => {handleDelete()}}>Delete</Button>
                          </div>
                        </div>)
  }
 
  

  const ExternalLinkEditor = ({item, index, handleNavigationDelete, handleNavigationChange}) => {
    const [title, setTitle] = useState(item['title'])
    const [link, setLink] = useState(item['link'])
    const [type, setType] = useState(item['type']) 
    const [items, setItems] = useState(item['items'])

    const handleDelete = () => {
      handleNavigationDelete(index)
    }

    const handleChange = (e, t) => {
      if (t=='title') {       
        setTitle(e.target.value)

      }
      if (t=='link') {
        setLink(e.target.value)
      }
    } 

  const handleSave = () => {
    console.log({...item, title: title, type: type, link: link, items: [...items]})
    handleNavigationChange(index, {...item, title: title, type: type, link: link, items: [...items]})
  }

   const handleDropdownDelete = (i) => {
     setItems([...items.splice(i, 1)])
   }

   const handleDropDownChange = (i, newItem) => {
      setItems([...items.slice(0,i),newItem, ...items.slice(i+1)])
   }

    const addItemsNewUrls = () => {
      const n = {
          "link": "http://",
          "title": "",
          "type": "externalLink",
          "display": "bottom",
          "token": "",
          "index": "",
          "style": {"bgColor": ['#FFFBC7','#EAEEF1','#DCC7E1'][Math.floor(Math.random() * 3)]}
      }
      setItems([...items, n])
  }
    
    return (<div style={{margin: '10px', padding: '10px', borderRadius: '5px', maxWidth: '67vw', width: '67vw', backgroundColor: item['style']['bgColor']}}>
                          <Select
                            fullWidth
                            labelId="simple-select-label"
                            id="simple-select"
                            value={type}
                            label="Type"
                            onChange={(e) => setType(e.target.value)}
                          >    
                            <MenuItem value={'externalLink'}>externalLink</MenuItem>
                            <MenuItem value={'dropdown'}>dropdown</MenuItem>
                           </Select>
                              
                           <TextField label='Title' fullWidth  value={title} onChange={(e) => handleChange(e, 'title')}/>
                           {type == 'dropdown' ? <></> : <TextField label='Link' fullWidth value={link} onChange={(e) => handleChange(e, 'link')}/>}

                          {type == 'dropdown' ? 
                            <div style={{ border: '1px solid #ffb5b5', marginTop: '10px', padding: '18px', borderRadius: '5px'}}> {items.map((item, iindex) => {
                              return (<DropdownItemEditor key={iindex} item={item} index={iindex} handleDropdownDelete={handleDropdownDelete} handleDropDownChange={handleDropDownChange}/>)
                            })}<Button sx={{ textTransform: 'none' }}  onClick={() => addItemsNewUrls()}>New</Button> </div> : <></>}

                          <div style={{display: 'flex', justifyContent: 'flex-end',width: '100%'}}>
                           <Button onClick={() => {handleDelete()}}>Delete</Button>
                           <Button onClick={() => {handleSave()}}>Save</Button>   
                          </div>
                        </div>)
  }


  const handleBgImageChange = (e, i) => {
    const newBgUrls = [...bgUrls]
    newBgUrls[i] = e.target.value
    setBgUrls([...newBgUrls])
  }

  const deleteBgImage = (i) => {
    setBgUrls([...bgUrls])
  }

  const BgImageUrlEditor = ({item, index}) => {
    return (<div style={{padding: '10px', borderRadius: '5px', width: '67vw'}}>
                           <TextField label='Url' fullWidth value={item} onChange={() => handleBgImageChange(event, index)}/>
                           {false ? <Button onClick={() => {deleteBgImage(index)}}>Delete</Button>:<></>}
                        </div>)
  }
  
   const SettingsUrls =  urlList.map((item, index) => {
          
                    if (item.type == 'externalLink' || item.type == 'dropdown') {
                      return <ExternalLinkEditor item={item} key={index} index={index} handleNavigationChange={handleNavigationChange} handleNavigationDelete={handleNavigationDelete} />
                    }
     
                })   

   const BgImageUrls =  bgUrls.map((item, index) => {
                      return <BgImageUrlEditor item={item} key={index} index={index}/>
                })   


  const downloadSettings = () => {
    downloadJson({'navigation': urlList, 'style': {'background-images': bgUrls}}, 'desktop.json')
  }

  const downloadJson = (j, name) => {
    var jsonStr = JSON.stringify(j, null, 2);
    var blob = new Blob([jsonStr], {type: "application/json"});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const uploadSettings = () => {
    handleHiddenInputClick()
  }

  const handleReadFile = () => {
    const file = fileInput.current.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;
        const cj = JSON.parse(content)
        console.log(cj)
        setUrlList([...cj['navigation']])
        setBgUrls([...cj['style']['background-images']])
      };

      reader.readAsText(file);
    } else {
      alert('No file selected.');
    }
  }

  const handleHiddenInputClick = () => {
    fileInput.current.click(); // Programmatically click the hidden file input
  }


   return (
     <BootstrapDialog  onClose={handleClose} open={props.showBambooSettings} maxWidth={'100vw'} style={{ maxHeight: '100vh', maxWidth: '100vw', overflowY: 'unset', overflow: 'unset' }}>
     <DialogContent dividers={true} style={{ overflowY: 'unset' }}>
    <Box
      style={{ overflowY: 'unset' }}
      sx={{maxHeight: '60vh', flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '60vh', width: '80vw' }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleTabChange}
        aria-label="Vertical tabs example"
        sx={{ maxHeight: '100vh', borderRight: 1, borderColor: 'divider' }}
      >
        <Tab  sx={{ textTransform: 'none' }} label="Navigation" {...a11yProps(0)} />
        <Tab  sx={{ textTransform: 'none' }} label="Background" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0} style={{overflowY: 'scroll'}}>
              {SettingsUrls}
              <Button sx={{ textTransform: 'none' }}  onClick={() => addNewUrls()}>New</Button>   
      </TabPanel>
      <TabPanel value={value} index={1}>
              {BgImageUrls}
              {false ? <Button sx={{ textTransform: 'none' }}  onClick={() => addNewUrls()}>New</Button> : <></>}
              <a style={{marginLeft: '2px'}} href="https://unsplash.com/s/photos/desktop-wallpapers" target="_blank">Upsplash</a>
      </TabPanel>
    </Box>
        </DialogContent>
           <DialogActions>
              <Button sx={{ textTransform: 'none' }}  onClick={() => downloadSettings()}>Download</Button>     
              <Button sx={{ textTransform: 'none' }}  onClick={() => uploadSettings()}>Upload</Button>
              <Button sx={{ textTransform: 'none' }}  onClick={() => handleSave()}>Save</Button>     
              <Button sx={{ textTransform: 'none' }}  onClick={() => handleClose()}>Close</Button>
              <input
                type="file"
                accept=".json"
                ref={fileInput}
                style={{ display: 'none' }} // Hide the file input
                onChange={handleReadFile} // Trigger reading when file is selected
              />
           </DialogActions>
     </BootstrapDialog>
   )     
                          
}

export  { SettingPanel }
