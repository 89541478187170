import { useState,useRef, useEffect } from 'react';
import '../store/article.js'
import React from 'react';
import {Button, 
        TextField,
        Alert,
        Card,
        CardContent,
        CardMedia,
        Typography,
        CardActionArea
       } from '@mui/material';


const DesktopCover = (props) => {
  const [contentHeight, setContentHeight] = useState('0px')
  const contentContainer = useRef(null)


  useEffect(() => {
    contentContainer.current.style.height = contentContainer.current.scrollHeight + 'px';
  },[])

  return (
     <div 
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'antiquewhite',
        position: 'fixed',
        top: '0px',
        left: '0px',
        zIndex: 100
       }}>
      <pre  ref={contentContainer} style={{
                    marginLeft: '5vw',
                    maxWidth: '90vw',
                    maxHeight: '100vh',
                    overflow: 'scroll',
                    whiteSpace: 'pre-wrap'
                }}>{window.renjianshi}</pre>
    </div>
    )
}

export {DesktopCover}