import { useState,useRef, useEffect } from 'react'
import {Clock} from './Clock.js'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import InsightsIcon from '@mui/icons-material/Insights';
import {Dify} from './Dify.js'




const ToolBox = (props) => {
  const [openDify, setOpenDify] = useState(false)

  
  return (
    <div className="tool-box">
      <div className="tool-box-items">
        <SettingsOutlinedIcon onClick={() => {props.dialog['switchSetting']()}} className=".tool-box-item" sx={{ fontSize: 54, color: '#FFFBC7' }}/>    
        <Clock className=".tool-box-item"/>
        <InsightsIcon onClick={() => {setOpenDify(true)}} className=".tool-box-item" sx={{ fontSize: 54, color: '#FFFBC7' }}/>  
        </div>
        <Dify openDify={openDify} setOpenDify={setOpenDify}/>
    </div>)
}

export {ToolBox}