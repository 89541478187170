import { useState,useRef, StrictMode } from 'react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {BambooDesktop} from './/bambooDesktop.js'
import BackgroundPng from './assets/background.png'


const home = async () => {
   window.urlList = []
   window.bgUrls = []
  
   if (!localStorage.getItem('urlList')) {
    const u = {
        "link": "http://",
        "title": "",
        "type": "externalLink",
        "display": "bottom",
        "token": "",
        "items": [],
        "index": "",
        "style": {"bgColor": ['#FFFBC7','#EAEEF1','#DCC7E1'][Math.floor(Math.random() * 3)]}
    }
    window.urlList = []
    const defaultUrls = [
      ['Github', 'https://github.com/trending', 'dropdown', [
        ['trending', 'https://github.com/trending', 'externalLink'], 
      ]],
      ['bing', 'https://cn.bing.com', 'externalLink'],
      
      ['models', 'https://huggingface.co/models', 'externalLink'],
      //['setting', 'switchSetting', 'dialog'],      
    ]
    window.urlList = defaultUrls.map(item => {
      const nu = {...u}
      nu['title'] = item[0]
      nu['link'] = item[1]
      nu['type'] = item[2]
      if (nu['type'] == 'dropdown') {
        nu['items'] = item[3].map(i => {
          const nu = {...u}
          nu['title'] = i[0]
          nu['link'] = i[1]
          nu['type'] = i[2]
          return nu
        })
      }
      return nu
    })
    localStorage.setItem('urlList', JSON.stringify(window.urlList))
   }

   if (!localStorage.getItem('bgUrls')) {
     window.bgUrls = [BackgroundPng]
     localStorage.setItem('bgUrls', JSON.stringify(window.bgUrls))
   }

  
   
   const root = createRoot(document.getElementById('bamboo-desktop'));
   root.render(
     <StrictMode>
     <BambooDesktop/>
     </StrictMode>     
  ); 
}

home()

