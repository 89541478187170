import { useState,useRef, useEffect } from 'react';
import React from 'react';
import  axios from 'axios'
import { createRoot } from 'react-dom/client';
import {QRCodeSVG} from 'qrcode.react';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import CodePng from './static/code.png'
import {DesktopCover} from './components/DesktopCover.js'
import {SettingPanel} from './components/SettingPanel.js'
import {ToolBox} from './components/ToolBox.js'
import {DesktopCoverBasic} from './components/DesktopCoverBasic.js'
import {SwitchLine} from './components/SwitchLine.js'
import {HeaderNavigation} from './components/HeadNavigation.js'
import {Button, 
        TextField,
        List,
        ListItem,
        ListItemIcon,
        ListItemText,
        ListSubheader,
        Dialog,
        DialogActions,
        DialogContent,
        DialogContentText,
        DialogTitle,
        Snackbar,
        Alert,
        Card,
        CardContent,
        CardMedia,
        Typography,
        CardActionArea
       } from '@mui/material';


const BambooDesktop = (props) =>{
  const [showBambooSettings, setShowBambooSettings] = useState(false)
  const [urlList,setUrlList] = useState([]) 
  const [desktopCover, setDesktopCover] = useState(false)
  const [openMessage,setOpenMessage] = useState(false)
  const homeTips = 'Welcome'
  const message = useRef(homeTips);
  //toast(message.current, { autoClose: 600 })
  

  
   const refreshSiteList = () => {
      try{
        const u = JSON.parse(localStorage.getItem('urlList'))
         setUrlList([...u])    
         console.log(u)
      } catch (e) { console.log(e) }

   }
   
   useEffect(() => {
      refreshSiteList()
   }, [])
  

  
  const Message = (props) => { 
    let [open, setOpen] = useState(props.openMessage)
    const handleMessageClose = () => { 
      setOpen(false)
      props.openMessage = false
      setOpenMessage(false)
      console.log(open)
    }    
    
    return (
     <Snackbar
       open={open}
       autoHideDuration={1500}
       onClose={handleMessageClose}
     >
        <Alert
            severity="success" 
            sx={{ width: '100%' }}>
          {message.current}
        </Alert>
     </Snackbar>
    )
  }
  
  const openSite = (u) => {
     window.open(u, '_blank')
  }
  

  const switchSetting = () => {
    setShowBambooSettings(!showBambooSettings)
  }

  const switchDesktopCover = () => {
    setDesktopCover(!desktopCover)
  } 

  const GrowthCard = () => {
     return  <div>
                       <Typography className="growth-card-info"  variant="body2" color="text.secondary">
                         {true ? <QRCodeSVG value={item['url']} fgColor={['#008E59','#F2DE76','#7BA1A8'][Math.floor(Math.random() * 3)]}/> : <img  src={CodePng} style={{
                           width: '20px', 
                           height: '20px', 
                           cursor: 'pointer', 
                           marginLeft: '2px'}}/>}
                       </Typography>
                </div>
  }

  
  return (
          <div>
             <HeaderNavigation 
              urlList={urlList} 
              dialog={{'switchSetting': switchSetting}}/>
             <DesktopCoverBasic/>
             {desktopCover ? <DesktopCover/>:<></>}
             <SwitchLine switchDesktopCover={switchDesktopCover}/>
             <ToolBox dialog={{'switchSetting': switchSetting}}/>
             <SettingPanel 
              refreshSiteList={refreshSiteList}
              setShowBambooSettings={setShowBambooSettings} 
              showBambooSettings={showBambooSettings}/>
             <Message openMessage={openMessage}/>
             <ToastContainer autoClose={8000} />
          </div>
      )
    
}


export {BambooDesktop}
