import React, { useState, useEffect } from 'react';
import { Paper, Typography } from '@mui/material';

const Clock = () => {
  const [time, setTime] = useState(new Date());
  const [w, setW] = useState(50) 
  const [moonStyle, setMoonStyle] = useState({
      position: 'relative',
      top: '0vh',
      right: '0vw',
      width: String(w)+'px',
      height: String(w)+'px',
      borderRadius: '50%',
      border: '0.01px solid yellow',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.2) inset',
    })


  const switchMoon = () => {
    console.log('moon')
    if (moonStyle.position != 'fixed') {
      setW(200)
      setMoonStyle({...moonStyle, 
                    position: 'fixed', 
                    top: '15vh', 
                    right: '20vw',
                    width: '200px',
                    height: '200px',})
    } else {
      setW(20)
      setMoonStyle({...moonStyle, 
                    position: 'relative', 
                    top: '0vh', 
                    right: '0vw',
                    width: '50px',
                    height: '50px',})
    }

  }

  useEffect(() => {
    // var viewportWidth = window.innerWidth;
    // console.log(viewportWidth)
    // setW(viewportWidth/2)
    
    const interval = setInterval(() => {
      setTime(new Date());
    }, 20);
    return () => clearInterval(interval);
  }, []);


  const handStyle = {
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: '#1976d2',
    transformOrigin: '50% 100%',
    top: '50%',
  };
  
  const markerContainerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const markerStyle = {
    position: 'absolute',
    width: String(w*0.005) + 'px',
    height: String(w*0.005) + 'px',
    borderRadius: '50%',
    backgroundColor: 'red',
  };

  const hourMarkers = Array.from({ length: 12 }).map((_, index) => {
    const angle = index * 30;
    const markerPositionX = Math.sin((angle * Math.PI) / (w*0.9)) * (w*0.49);
    const markerPositionY = 0-Math.cos((angle * Math.PI) / (w*0.9)) * (w*0.49);
    const hour = (index -1) % 12 + 1; // Align markers with current hour
    return (
      <div
        key={index}
        style={{
          ...markerStyle,
          width: String(w*0.01) + 'px',
          height: String(w*0.01) + 'px',
          borderRadius: '50%',
          zIndex: '2',
          backgroundColor: 'black',
          transform: `translate(-50%, -50%)`,
          left: `${markerPositionX}px`,
          top: `${markerPositionY}px`,
        }}
      >
        {false ? hour : ''}
      </div>
    );
  });

  const minuteMarkers = Array.from({ length: 60 }).map((_, index) => {
    const angle = index * 6;
    const markerPositionX = Math.sin((angle * Math.PI) / (w*0.9)) * (w*0.49);
    const markerPositionY = Math.cos((angle * Math.PI) / (w*0.9)) * (w*0.49);
    return (
      <div
        key={index}
        style={{
          ...markerStyle,
          transform: `translate(-50%, -50%)`,
          left: `${markerPositionX}px`,
          top: `${markerPositionY}px`,
        }}
      />
    );
  });

  // const hourDeg = (time.getHours() % 12) * 30 + time.getMinutes() * 0.5;
  // const minuteDeg = time.getMinutes() * 6 + time.getSeconds() * 0.1;  
  const hourDeg = ((time.getHours() % 12) * 3600 + time.getMinutes() * 60 + time.getSeconds()) * (360 / 43200);
  const minuteDeg = (time.getMinutes() * 60000 + time.getSeconds() * 1000 + time.getMilliseconds()) * (360 / 3600000);
  // const secondDeg = time.getSeconds() * 6;
  const secondDeg = (time.getSeconds() * 1000 + time.getMilliseconds()) * (360 / 60000);
  const millisecondDeg = (time.getMilliseconds() / 1000) * 360;

  return (
    <Paper style={moonStyle} onClick={switchMoon}>
      <div style={markerContainerStyle}>
        {hourMarkers}
        {minuteMarkers}
      </div>
      <div
        style={{
          ...handStyle,
          height: '32%',
          width: '1px',
          zIndex: '3',
          backgroundColor: 'black',
          transform: `translate(-50%, -100%) rotate(${hourDeg}deg)`,
          left: '50%',
        }}
      ></div>
      <div
        style={{
          ...handStyle,
          height: '40%',
          width: '1px',
          zIndex: '2',
          backgroundColor: 'black',
          transform: `translate(-50%, -100%) rotate(${minuteDeg}deg)`,
          left: '50%',
        }}
      ></div>
      <div
        style={{
          ...handStyle,
          height: '47.6%',
          width: '1px',
          zIndex: '1',
          backgroundColor: 'red',
          transform: `translate(-50%, -100%) rotate(${secondDeg}deg)`,
          left: '50%',
        }}
      ></div>

      <div
        style={{
          ...handStyle,
          height: '55%',
          display: 'none',
          width: '1px',
          zIndex: '0',
          backgroundColor: 'blue',
          //transform: `translate(-50%, -100%) rotate(${millisecondDeg}deg)`,
          left: '50%',
        }}
      ></div>
      
      <div
        style={{
          position: 'absolute',
          width: '3px',
          height: '3px',
          zIndex: '5',
          backgroundColor: 'red',
          borderRadius: '50%',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
          transform: 'translate(-50%, -50%)',
          left: '50%',
          top: '50%',
        }}
      ></div>
      { false ? <Typography variant="h6" style={{ textAlign: 'center', marginTop: '8px' }}>
        {time.toLocaleTimeString()}
      </Typography> : <></>} 
    </Paper>
  );
};

export { Clock };
