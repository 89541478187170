import { useState,useRef, useEffect } from 'react';
import React from 'react';

const DesktopCoverBasic = (props) => {
  const bgUrl = JSON.parse(localStorage.getItem('bgUrls'))[0]

  useEffect(() => {
     if (localStorage.getItem('bgUrls')) {
        let bgUrls = []
        try{bgUrls = JSON.parse(localStorage.getItem('bgUrls'))} catch(e){console.log(e)}
        window.bgUrls = [...bgUrls]
     }
  })

  return (
     <div 
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'antiquewhite',
        position: 'fixed',
        top: '0px',
        left: '0px',
        zIndex: -1,
        backgroundImage: `url(${bgUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
       }}>
    </div>
    )
}

export {DesktopCoverBasic}