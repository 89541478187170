window.renjianshi = `
  颜回见仲尼，请行。曰：“奚之？”曰：“将之卫。”曰：“奚为焉？”曰：“回闻卫君，其年壮，其行独。轻用其国而不见其过。轻用民死，死者以国量，乎泽若蕉，民其无如矣！回尝闻之夫子曰：‘治国去之，乱国就之。医门多疾。’愿以所闻思其则，庶几其国有瘳乎！”

　　仲尼曰：“嘻，若殆往而刑耳！夫道不欲杂，杂则多，多则扰，扰则忧，忧而不救。古之至人，先存诸己而后存诸人。所存于己者未定，何暇至于暴人之所行！且若亦知夫德之所荡而知之所为出乎哉？德荡乎名，知出乎争。名也者，相轧也；知也者，争之器也。二者凶器，非所以尽行也。

　　且德厚信矼，未达人气；名闻不争，未达人心。而强以仁义绳墨之言術暴人之前者，是以人恶有其美也，命之曰灾人。灾人者，人必反灾之。若殆为人灾夫。

　　且苟为人悦贤而恶不肖，恶用而求有以异？若唯无诏，王公必将乘人而斗其捷。而目将荧之，而色将平之，口将营之，容将形之，心且成之。是以火救火，以水救水，名之曰益多。顺始无穷，若殆以不信厚言，必死于暴人之前矣！

　　且昔者桀杀关龙逄，纣杀王子比干，是皆修其身以下伛拊人之民，以下拂其上者也，故其君因其修以挤之。是好名者也。

　　昔者尧攻丛枝、胥、敖，禹攻有扈。国为虚厉，身为刑戮。其用兵不止，其求实无已，是皆求名实者也，而独不闻之乎？名实者，圣人之所不能胜也，而况若乎！虽然，若必有以也，尝以语我来。”

　　颜回曰：“端而虚，勉而一，则可乎？”曰：“恶！恶可！夫以阳为充孔扬，采色不定，常人之所不违，因案人之所感，以求容与其心，名之曰日渐之德不成，而况大德乎！将执而不化，外合而内不訾，其庸讵可乎！”

　　“然则我内直而外曲，成而上比。内直者，与天为徒。与天为徒者，知天子之与己，皆天之所子，而独以己言蕲乎而人善之，蕲乎而人不善之邪？若然者，人谓之童子，是之谓与天为徒。外曲者，与人之为徒也。擎跽曲拳，人臣之礼也。人皆为之，吾敢不为邪？为人之所为者，人亦无疵焉，是之谓与人为徒。成而上比者，与古为徒。其言虽教，谪之实也，古之有也，非吾有也。若然者，虽直而不病，是之谓与古为徒。若是则可乎？”仲尼曰：“恶！恶可！大多政法而不谍。虽固，亦无罪。虽然，止是耳矣，夫胡可以及化！犹师心者也。”

　　颜回曰：“吾无以进矣，敢问其方。”仲尼曰：“斋，吾将语若。有心而为之，其易邪？易之者，皞天不宜。”颜回曰：“回之家贫，唯不饮酒不茹荤者数月矣。如此则可以为斋乎？”曰：“是祭祀之斋，非心斋也。”

　　回曰：“敢问心斋。”仲尼曰：“若一志，无听之以耳而听之以心；无听之以心而听之以气。听止于耳，心止于符。气也者，虚而待物者也。唯道集虚。虚者，心斋也”

　　颜回曰：“回之未始得使，实自回也；得使之也，未始有回也，可谓虚乎？”夫子曰：“尽矣！吾语若：若能入游其樊而无感其名，入则鸣，不入则止。无门无毒，一宅而寓于不得已则几矣。绝迹易，无行地难。为人使易以伪，为天使难以伪。闻以有翼飞者矣，未闻以无翼飞者也；闻以有知知者矣，未闻以无知知者也。瞻彼阕者，虚室生白，吉祥止止。夫且不止，是之谓坐驰。夫徇耳目内通而外于心知，鬼神将来舍，而况人乎！是万物之化也，禹、舜之所纽也，伏戏、几蘧之所行终，而况散焉者乎！”

　　叶公子高将使于齐，问于仲尼曰：“王使诸梁也甚重。齐之待使者，盖将甚敬而不急。匹夫犹未可动也，而况诸侯乎！吾甚栗之。子常语诸梁也曰：‘凡事若小若大，寡不道以欢成。事若不成，则必有人道之患；事若成，则必有阴阳之患。若成若不成而后无患者，唯有德者能之。’吾食也执粗而不臧，爨无欲清之人。今吾朝受命而夕饮冰，我其内热与！吾未至乎事之情而既有阴阳之患矣！事若不成，必有人道之患，是两也。为人臣者不足以任之，子其有以语我来！”

　　仲尼曰：“天下有大戒二：其一命也，其一义也。子之爱亲，命也，不可解于心；臣之事君，义也，无适而非君也，无所逃于天地之间。是之谓大戒。是以夫事其亲者，不择地而安之，孝之至也；夫事其君者，不择事而安之，忠之盛也；自事其心者，哀乐不易施乎前，知其不可奈何而安之若命，德之至也。为人臣子者，固有所不得已。行事之情而忘其身，何暇至于悦生而恶死！夫子其行可矣！

　　丘请复以所闻：凡交近则必相靡以信，远则必忠之以言。言必或传之。夫传两喜两怒之言，天下之难者也。夫两喜必多溢美之言，两怒必多溢恶之言。凡溢之类妄，妄则其信之也莫，莫则传言者殃。故法言曰：‘传其常情，无传其溢言，则几乎全。’且以巧斗力者，始乎阳，常卒乎阴，泰至则多奇巧；以礼饮酒者，始乎治，常卒乎乱，泰至则多奇乐。凡事亦然，始乎谅，常卒乎鄙；其作始也简，其将毕也必巨。

　　言者，风波也；行者，实丧也。夫风波易以动，实丧易以危。故忿设无由，巧言偏辞。兽死不择音，气息勃然于是并生心厉。剋核太至，则必有不肖之心应之而不知其然也。苟为不知其然也，孰知其所终！故法言曰：‘无迁令，无劝成。过度益也。’迁令劝成殆事。美成在久，恶成不及改，可不慎与！且夫乘物以游心，托不得已以养中，至矣。何作为报也！莫若为致命，此其难者？”

　　颜阖将傅卫灵公大子，而问于蘧伯玉曰；“有人于此，其德天杀。与之为无方则危吾国，与之为有方则危吾身。其知适足以知人之过，而不知其所以过。若然者，吾奈之何？”

　　蘧伯玉曰：“善哉问乎！戒之，慎之，正女身哉！形莫若就，心莫若和。虽然，之二者有患。就不欲入，和不欲出。形就而入，且为颠为灭，为崩为蹶；心和而出，且为声为名，为妖为孽。彼且为婴儿，亦与之为婴儿；彼且为无町畦，亦与之为无町畦；彼且为无崖，亦与之为无崖；达之，入于无疵。

　　汝不知夫螳螂乎？怒其臂以当车辙，不知其不胜任也，是其才之美者也。戒之，慎之，积伐而美者以犯之，几矣！

　　汝不知夫养虎者乎？不敢以生物与之，为其杀之之怒也；不敢以全物与之，为其决之之怒也。时其饥饱，达其怒心。虎之与人异类，而媚养己者，顺也；故其杀者，逆也。

　　夫爱马者，以筐盛矢，以蜃盛溺。适有蚊虻仆缘，而拊之不时，则缺衔毁首碎胸。意有所至而爱有所亡。可不慎邪？”

　　匠石之齐，至于曲辕，见栎社树。其大蔽数千牛，絜之百围，其高临山十仞而后有枝，其可以舟者旁十数。观者如市，匠伯不顾，遂行不辍。弟子厌观之，走及匠石，曰：‘自吾执斧斤以随夫子，未尝见材如此其美也。先生不肯视，行不辍，何邪？”曰：“已矣，勿言之矣！散木也。以为舟则沉，以为棺椁则速腐，以为器则速毁，以为门户则液瞒，以为柱则蠹，是不材之木也。无所可用，故能若是之寿。”

　　匠石归，栎社见梦曰：“女将恶乎比予哉？若将比予于文木邪？夫楂梨橘柚果蓏之属，实熟则剥，剥则辱。大枝折，小枝泄。此以其能苦其生者也。故不终其天年而中道夭，自掊击于世俗者也。物莫不若是。且予求无所可用久矣！几死，乃今得之，为予大用。使予也而有用，且得有此大也邪？且也若与予也皆物也，奈何哉其相物也？而几死之散人，又恶知散木！”匠石觉而诊其梦。弟子曰：“趣取无用，则为社何邪？”曰：“密！若无言！彼亦直寄焉！以为不知己者诟厉也。不为社者，且几有翦乎！且也彼其所保与众异，而以义喻之，不亦远乎！”

　　南伯子綦游乎商之丘，见大木焉，有异：结驷千乘，隐将芘其所藾。子綦曰：“此何木也哉！此必有异材夫！”仰而视其细枝，则拳曲而不可以为栋梁；俯而视其大根，则轴解而不可以为棺椁；舐其叶，则口烂而为伤；嗅之则使人狂酲三日而不已。子綦曰“此果不材之木也，以至于此其大也。嗟乎神人，以此不材。”

　　宋有荆氏者，宜楸柏桑。其拱把而上者，求狙猴之杙斩之；三围四围，求高名之丽者斩之；七围八围，贵人富商之家求禅傍者斩之。故未终其天年而中道之夭于斧斤，此材之患也。故解之以牛之白颡者，与豚之亢鼻者，与人有痔病者，不可以适河。此皆巫祝以知之矣，所以为不祥也。此乃神人之所以为大祥也。

　　支离疏者，颐隐于齐，肩高于顶，会撮指天，五管在上，两髀为胁。挫针治繲，足以餬口；鼓荚播精，足以食十人。上征武士，则支离攘臂于其间；上有大役，则支离以有常疾不受功；上与病者粟，则受三锺与十束薪。夫支离者其形者，犹足以养其身，终其天年，又况支离其德者乎！

　　孔子适楚，楚狂接舆游其门曰：“凤兮凤兮，何如德之衰也。来世不可待，往世不可追也。天下有道，圣人成焉；天下无道，圣人生焉。方今之时，仅免刑焉！福轻乎羽，莫之知载；祸重乎地，莫之知避。已乎，已乎！临人以德。殆乎，殆乎！画地而趋。迷阳迷阳，无伤吾行。吾行郤曲，无伤吾足。”

　　山木，自寇也；膏火，自煎也。桂可食，故伐之；漆可用，故割之。人皆知有用之用，而莫知无用之用也。`