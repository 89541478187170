import { useState,useRef, useEffect } from 'react';
import React from 'react';
import {Button
       } from '@mui/material';


const SwitchLine = (props) => {

  return (
     <div>
      <div
        style={{
          width: '0.5px',
          backgroundColor: '#7D929F',
          position: 'fixed',
          top: '0px',
          right: '16px',
          height: '80px',
          zIndex: 101
         }}>
      </div>
      <div  
        onClick={props.switchDesktopCover}
        style={{
          height: '10px',
          width: '10px',
          position: 'fixed',
          top: '80px',
          right: '10px',
          zIndex: '101',
          border: '1px solid red',
          borderRadius: '50%'
         }}>
      </div>
    </div>
    )
}

export {SwitchLine}