import { useState,useRef, useEffect } from 'react'
import {
          Button,
          Card,
          CardContent,
          Typography,
          Dialog,
          DialogTitle,
          DialogContent,
          DialogActions,
       } from '@mui/material';


const Dify = (props) => {

   const handleClose = () => {
     props.setOpenDify(false)
   }

   return (
     <Dialog onClose={handleClose} open={props.openDify} maxWidth={'100vw'}  width='80vw' style={{ maxHeight: '100vh', maxWidth: '100vw' }}>
       <iframe
         src="https://3nyeuxcmei6tzlqq05.tingqi.xyz/chatbot/Me3hHEttn9bX1W7p"
         style={{width: '80vw', height: '100%', minHeight: '700px'}}
         frameBorder="0"
         allow="microphone">
        </iframe>
     </Dialog>
   )     
                          
}

export  { Dify }
